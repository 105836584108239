import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/home/futurasit/futurasit-web/components/Navbar/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/futurasit/futurasit-web/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/home/futurasit/futurasit-web/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/futurasit/futurasit-web/utils/i18n/useClientTranslation.ts");
;
import(/* webpackMode: "eager" */ "/home/futurasit/futurasit-web/utils/index.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/futurasit/futurasit-web/utils/ThemeRegistry.js");
