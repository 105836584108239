'use client';
import React, { useEffect, useState } from 'react';
import { useClientTranslation } from '@/utils/i18n';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { usePathname, useRouter } from 'next/navigation';
import { ImageWithFallback } from '../ImageWithFallback';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageIcon from '@mui/icons-material/Language';
import Link from 'next/link';
import { useMediaQuery } from '@mui/system';

const pages: Array<{
  label: string;
  path: string;
}> = [
  { label: 'businessLine', path: '/#businessLine' },
  { label: 'products', path: '/#products' },
  { label: 'servicesNav', path: '/#services' },
  { label: 'aboutUs', path: '/aboutUs' },
];

type NavbarProps = {
  lng: string;
};

export const Navbar = ({ lng }: NavbarProps) => {
  const { t } = useClientTranslation(lng, ['common']);

  const pathname = usePathname();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isTop, setIsTop] = useState<boolean>(false);
  const router = useRouter();
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handlerRedirect = (path: string) => {
    router.push(`/${lng}${path}`);
  };
  const handlerChangeLang = () => {
    if (lng === 'es') {
      router.push(pathname.replace('/es', '/en'));
    } else {
      router.push(pathname.replace('/en', '/es'));
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setIsTop(position > 100);
  };

  useEffect(() => {
    if (pathname === '/es' || pathname === '/en') {
      handleScroll();
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    return setIsTop(true);
  }, [pathname]);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: 9999,
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: isTop || isMobile ? 'blur(5px)' : '',
        background:
          isTop || isMobile
            ? (ThemeBasic) => ThemeBasic.palette.gray.dark
            : 'transparent',
        boxShadow: 'none',
        ...(isTop || isMobile
          ? {
              top: 0,
            }
          : { bottom: 0, top: 'unset' }),
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          borderBottom: isTop ? '' : '2px solid',
          borderColor: (ThemeBasic) => ThemeBasic.palette.secondary.main,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Link href={`/${lng}`}>
            <Box sx={{ width: { xs: '70%', md: '80%' }, padding: '5px 0px' }}>
              <ImageWithFallback
                src="/static/logo-color301x67.png"
                fallbackImage="/static/logo-color301x67.png"
                alt="logo"
                width={301}
                height={67}
                style={{ width: '100%', height: 'auto' }}
              />
            </Box>
          </Link>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{
                color: (theme) => theme.palette.secondary.main,
              }}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.path} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{t(page.label)}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <Button
                key={page.path}
                onClick={() => handlerRedirect(page.path)}
                variant={
                  pathname === page.path ? 'ButtonNavbarActive' : 'ButtonNavbar'
                }
                sx={{ display: 'block' }}
              >
                {t(page.label)}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            {/* <Button variant="ButtonNavbarAction" title={t('changeTheme')}>
              <LightModeIcon />
            </Button> */}
            <Button
              variant="ButtonNavbarAction"
              title={t('changeLanguage')}
              onClick={handlerChangeLang}
            >
              <LanguageIcon /> <span>{lng}</span>
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
