import { Opacity } from '@mui/icons-material';
import { createTheme } from '@mui/material/styles';
import {
  fontWeight,
  height,
  positions,
  textAlign,
  textTransform,
  width,
} from '@mui/system';

const colorWhite = 'white';
const colorPrimary = '#17172D';
const colorSecondary = '#6038C1';
const colorThird = '#B7B7EA';

const colorSecondaryLight = 'rgba(96, 56, 193,0.9)';
const arrowSectionDark = 'rgba(96, 56, 193,0.15)';
const arrowSectionLight = 'rgba(255, 255, 255,0.15)';
const colorFour = '#503099';
const colorFifth = 'rgba(255, 255, 255, 0.8)';
const colorNav = 'rgba(255, 255, 255, 0.8)';
const colorNavDark = 'rgba(23, 23, 45, 0.8)';
const colorGray = 'rgb(128,128,128)';
const colorGrayTwo = 'rgb(51,51,51)';
const colorLink = '#27b3f3';
const colorLinkHover = '#3d92b9';

declare module '@mui/material/styles' {
  interface Palette {
    darker: Palette['info'];
    white: Palette['info'];
    gray: Palette['info'];
    link: Palette['info'];
    arrowSection: Palette['info'];
  }
  interface PaletteOptions {
    darker: Palette['info'];
    white: Palette['info'];
    gray: Palette['info'];
    link: Palette['info'];
    arrowSection: Palette['info'];
  }
  interface PaletteColor {
    percent35?: string;
    percent50?: string;
  }
  interface SimplePaletteColorOptions {
    percent35?: string;
    percent50?: string;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    ButtonNavbar: true;
    ButtonCategory: true;
    ButtonNavbarActive: true;
    ButtonNavbarAction: true;
  }
}
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    Hero: true;
    HeaderSection: true;
    HeaderSectionWhite: true;
    BodySection: true;
  }
}

export const ThemeTypography = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Futura Regular',
    },
  },
});

export const ThemePalette = createTheme(ThemeTypography, {
  palette: {
    white: {
      main: `${colorWhite}`,
      light: '#EBF3FF',
      dark: '#DEE4ED',
      contrastText: colorFifth,
    },
    primary: {
      main: colorPrimary,
      light: colorThird,
      dark: colorFour,
      contrastText: colorWhite,
    },
    secondary: {
      dark: colorSecondary,
      main: colorSecondary,
      light: colorSecondaryLight,
      contrastText: colorWhite,
    },
    arrowSection: {
      dark: arrowSectionDark,
      main: colorSecondary,
      light: arrowSectionLight,
      contrastText: colorWhite,
    },
    darker: {
      main: `${colorFour}`,
      light: `${colorFifth}`,
      dark: `${colorNavDark}`,
      contrastText: colorWhite,
    },
    gray: {
      main: `${colorGray}`,
      light: `${colorGrayTwo}`,
      dark: `${colorNav}`,
      contrastText: colorFifth,
    },
    link: {
      main: `${colorLink}`,
      light: `${colorLink}`,
      dark: `${colorLinkHover}`,

      contrastText: colorLinkHover,
    },
  },
});

export const ThemeBasic = createTheme(ThemePalette, {
  components: {
    MuiCssBaseline: {
      styleOverrides: () => ({
        '.overlayPurple': {
          backgroundColor: ThemePalette.palette.secondary.dark,
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          opacity: 0.5,
        },
      }),
    },
    MuiButton: {
      variants: [
        {
          props: {
            variant: 'ButtonCategory',
          },
          style: {
            backgroundColor: 'transparent',
            color: ThemePalette.palette.darker.dark,
            textTransform: 'lowercase',
            fontSize: 'clamp(14px,3vw,18px)',
          },
        },
        {
          props: {
            variant: 'ButtonCategory',
            className: 'active',
          },
          style: {
            color: ThemePalette.palette.secondary.main,
            fontWeight: 'bold',
            position: 'relative',
            marginLeft: '1rem',
            '&:before,&:after': {
              content: '"^"',
              display: 'inline-block',
              transform: 'rotate(90deg)',
              fontSize: '16px',
              lineHeight: '16px',
              textShadow: '0 0 black',
              fontWeight: 'bold',
              position: 'absolute',
              top: 'calc(50% - 7px)',
            },
            '&:before': {
              left: '-0.6rem',
            },
            '&:after': {
              left: '-0.2rem',
            },
          },
        },
        {
          props: {
            variant: 'ButtonNavbar',
          },
          style: {
            backgroundColor: 'transparent',
            color: ThemePalette.palette.secondary.main,
            textTransform: 'lowercase',
            fontSize: 'clamp(14px,3vw,18px)',
          },
        },
        {
          props: {
            variant: 'ButtonNavbarActive',
          },
          style: {
            backgroundColor: 'transparent',
            color: ThemePalette.palette.secondary.main,
            fontWeight: 'bold',
            textTransform: 'lowercase',
            fontSize: 'clamp(14px,3vw,18px)',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: {
            variant: 'ButtonNavbarAction',
          },
          style: {
            boxShadow: `2px 2px 8px ${ThemePalette.palette.secondary.light}`,
            backgroundColor: 'transparent',
            marginLeft: '3px',
            color: ThemePalette.palette.secondary.main,
            fontSize: '15px',
            padding: '4px 5px',
            minWidth: '50px',
            minHeight: '35px',
            borderRadius: '5px',
            display: 'flex',
            alignItems: 'center',
            textTransform: 'lowercase',
            transition: '1s',
            span: {
              marginLeft: '3px',
            },
            '&:hover': {
              transition: '1s',
              backgroundColor: ThemePalette.palette.secondary.main,
              color: ThemePalette.palette.white.main,
            },
          },
        },
      ],
    },
    MuiTypography: {
      styleOverrides: {
        root: {},
      },
      defaultProps: {
        variantMapping: {
          Hero: 'h1',
          HeaderSection: 'h1',
          HeaderSectionWhite: 'h1',
          BodySection: 'p',
        },
      },
      variants: [
        {
          props: { variant: 'Hero' },
          style: {
            fontSize: 'clamp(1.2rem, 3vw, 3rem)',
            fontWeight: 'bold',
            lineHeight: 1.2,
            letterSpacing: '2px',
            color: ThemePalette.palette.white.main,
            textAlign: 'center',
            width: 'max-content',
            '@media (max-width:768px)': {
              fontSize: '1.5rem',
              marginTop: '1rem',
            },
          },
        },
        {
          props: { variant: 'HeaderSection' },
          style: {
            fontFamily: 'Futura Bold !important',
            fontSize: 'clamp(2rem, 3vw, 3.5rem)',
            lineHeight: 0.9,
            letterSpacing: '2px',
            color: ThemePalette.palette.secondary.main,
            '@media (max-width:768px)': {
              textAlign: 'center',
            },
          },
        },
        {
          props: { variant: 'HeaderSectionWhite' },
          style: {
            fontFamily: 'Futura Bold !important',
            fontSize: 'clamp(2rem, 3vw, 3.5rem)',
            lineHeight: 0.9,
            letterSpacing: '2px',
            color: ThemePalette.palette.white.main,
            '@media (max-width:768px)': {
              textAlign: 'center',
            },
          },
        },
        {
          props: { variant: 'BodySection' },
          style: {
            fontSize: 'clamp(1rem, 3vw, 1.6rem)',
            lineHeight: 1.4,
            letterSpacing: '2px',
            color: ThemePalette.palette.primary.main,
          },
        },
        {
          props: { variant: 'h1', className: 'TitleLineBusiness' },
          style: {
            fontSize: 'clamp(1rem, 3vw, 1.6rem)',
            lineHeight: 1.4,
            letterSpacing: '2px',
            color: ThemePalette.palette.primary.main,
            textAlign: 'center',
            '&:hover': {
              color: ThemePalette.palette.secondary.main,
              cursor: 'pointer',
            },
          },
        },
        {
          props: { variant: 'h1', className: 'TitleLineBusinessPrimary' },
          style: {
            fontSize: 'clamp(0.8rem, 3vw, 1.8rem)',
            lineHeight: 1.4,
            letterSpacing: '2px',
            color: ThemePalette.palette.secondary.main,
            textAlign: 'center',
            fontWeight: 'bold',
          },
        },
        {
          props: { variant: 'h1', className: 'TitleLineBusinessPrimaryArrow' },
          style: {
            fontSize: 'clamp(0.8rem, 3vw, 1.8rem)',
            lineHeight: 1.4,
            letterSpacing: '2px',
            color: ThemePalette.palette.secondary.main,
            textAlign: 'left',
            fontWeight: 'bold',
            position: 'relative',
            marginLeft: '2rem',
            '&:before,&:after': {
              content: '"^"',
              display: 'inline-block',
              transform: 'rotate(90deg)',
              fontSize: 'clamp(0.8rem, 3vw, 1.8rem)',
              lineHeight: 'clamp(0.8rem, 3vw, 1.8rem)',
              letterSpacing: '-10px',
              textShadow: '0 0 black',
              fontWeight: 'bold',
              position: 'absolute',
              top: '0px',
            },
            '&:before': {
              left: '-2.3rem',
            },
            '&:after': {
              left: '-1.8rem',
            },
          },
        },
        {
          props: { variant: 'h1', className: 'linkFooter' },
          style: {
            fontSize: 'clamp(0.8rem, 3vw, 1.3rem)',
            color: ThemePalette.palette.white.main,
            textAlign: 'left',
            '@media (max-width:768px)': {
              fontSize: 'clamp(1.2rem, 2vw, 2rem)',
              textDecoration: 'underline',
            },
          },
        },
        {
          props: { variant: 'h1', className: 'linkFooterName' },
          style: {
            fontSize: '1.4rem',
            color: ThemePalette.palette.white.main,
            textAlign: 'left',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            '@media (max-width:768px)': {
              fontSize: '1.2rem',
            },
          },
        },

        {
          props: { variant: 'h2', className: 'linkFooter' },
          style: {
            fontSize: 'clamp(0.8rem, 3vw, 1rem)',
            color: ThemePalette.palette.white.main,
            textAlign: 'left',
          },
        },
      ],
    },
  },
});

export default ThemeBasic;
